import React from 'react';
import { IonImg, IonItem, IonText } from '@ionic/react';

import './ProductPay.scss';
import { OrderPayProductList } from '../../models/Pay';
import { ProductStatusType } from '../../models/Product';
import { codeName } from '../../util/EnumUtils';
import { CodeObject } from '../../data/code/code.state';

interface ProductPayProps {
  orderPayProduct: OrderPayProductList;
  codes: CodeObject;
}

const ProductPay: React.FC<ProductPayProps> = ({ orderPayProduct, codes }) => {
  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return (
    <>
      <IonItem lines="none">
        <IonImg className="col_span" src={orderPayProduct.imageUrl} alt={orderPayProduct.productName} />
        <IonText>
          <div className="pay_box col_span">
            <div>
              <strong>{orderPayProduct.productName}</strong>
              {orderPayProduct.productStatusType === ProductStatusType.SALE ? (
                orderPayProduct.productStockQuantity > 0 ? (
                  orderPayProduct.productQuantity <= orderPayProduct.productStockQuantity ? (
                    <>
                      <p className="price">
                        <em>{numberWithCommas(orderPayProduct.productSalePrice)}</em>원
                      </p>
                      <p className="count">
                        <em>{orderPayProduct.productQuantity}</em>개
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="price out_of">
                        <em>{numberWithCommas(orderPayProduct.productSalePrice)}</em>원
                      </p>
                      <p className="count out_of">
                        <em>{orderPayProduct.productQuantity}</em>개
                      </p>
                      <p className="stock">
                        최대 <em>{orderPayProduct.productStockQuantity}</em>개 구매가능
                      </p>
                    </>
                  )
                ) : (
                  <p className="price out_of">
                    <em>{codeName(ProductStatusType.SOLD_OUT, codes.ProductStatusType)}</em>
                  </p>
                )
              ) : (
                <p className="price out_of">
                  <em>{codeName(orderPayProduct.productStatusType, codes.ProductStatusType)}</em>
                </p>
              )}
            </div>
          </div>
        </IonText>
      </IonItem>
    </>
  );
};

export default ProductPay;
