/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IonCard, IonCardContent, IonCol, IonImg, IonRouterLink, IonRow, IonText, useIonAlert } from '@ionic/react';
import './ProductCard.scss';
import { Product, ProductStatusType } from '../../models/Product';
import { cartInsert, cartRemove, cartTotal } from '../../data/dataApi';
import { CodeObject } from '../../data/code/code.state';
import { codeName } from '../../util/EnumUtils';

interface ProductCardProps {
  product: Product;
  categoryId: string;
  cart?: (cartChk: boolean, product: Product) => void;
  setCartTotal: Function;
  codes: CodeObject;
}

const ProductCard: React.FC<ProductCardProps> = ({ product, categoryId, cart, setCartTotal, codes }) => {
  const [cartOn, setCartOn] = useState(false); // 장바구니 리스트 조회 체크
  const [present] = useIonAlert(); // 폼
  const [presentDetail] = useIonAlert(); // 폼

  const cart_btn = async () => {
    if (product.cartOn) {
      await present({
        header: '모던한담',
        message: "'" + product.productName + "'" + ' 상품을 장바구니에서 삭제 하시겠습니까?',
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              return;
            },
          },
          {
            text: '확인',
            handler: async () => {
              const items: any = [{ productId: product.productId, cartId: product.cartId }];
              const removeCart = await cartRemove(items);
              if (removeCart.result) {
                product.cartOn = false;
                setCartOn(false);
                const getCartTotal = await cartTotal();
                if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
              } else {
                await presentDetail({
                  header: '모던한담',
                  message: '다시 시도해 주세요.',
                  buttons: [
                    {
                      text: '확인',
                      role: 'cancel',
                      cssClass: 'secondary',
                    },
                  ],
                });
                return;
              }
            },
          },
        ],
      });
    } else {
      if (product.productStatusType === ProductStatusType.SALE && product.productStockQuantity > 0) {
        const addCart = await cartInsert(product.productId, 1);
        if (addCart.result) {
          product.cartOn = true;
          setCartOn(true);
          if (cart != undefined) cart(true, product);
          const getCartTotal = await cartTotal();
          if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
        } else {
          await present({
            header: '모던한담',
            message: '다시 시도해 주세요.',
            buttons: [
              {
                text: '확인',
                role: 'cancel',
                cssClass: 'secondary',
              },
            ],
          });
          return;
        }
      } else if (product.productStockQuantity <= 0 || product.productStatusType === 'SOLD_OUT') {
        await present({
          header: '모던한담',
          message: `${codeName(ProductStatusType.SOLD_OUT, codes.ProductStatusType)} 상태의 상품은 장바구니에 담을 수 없습니다.`,
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      } else {
        await present({
          header: '모던한담',
          message: `${codeName(product.productStatusType, codes.ProductStatusType)} 상태의 상품은 장바구니에 담을 수 없습니다.`,
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
            },
          ],
        });
        return;
      }
    }
  };

  function numberWithCommas(x: number) {
    console.log(x);
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  useEffect(() => {
    if (product.cartOn) {
      setCartOn(true);
    } else {
      setCartOn(false);
    }
  }, [cartOn, product]);

  return (
    <>
      <IonCard className="product_card">
        <IonRouterLink routerLink={`/product/${product.productId}?categoryId=${categoryId}`}>
          <IonImg src={product.imageUrl} alt={product.productName} />
          <IonCardContent className="meat_con">
            <IonRow className="meat_info">
              <IonCol>
                <span>{product.weight}</span>
                {product.storageMethod ? <span>{product.storageMethod}</span> : null}
              </IonCol>
            </IonRow>

            <div className="tit_price_box">
              <IonRow className="meat_tit">
                <IonCol>{product.productName}</IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="meat_price">
                  {product.productStatusType === ProductStatusType.SALE ? (
                    product.productStockQuantity > 0 ? (
                      <>
                        <IonText>{numberWithCommas(product.productSalePrice)}</IonText>원
                      </>
                    ) : (
                      <IonText className="outOf">{codeName(ProductStatusType.SOLD_OUT, codes.ProductStatusType)}</IonText>
                    )
                  ) : (
                    <IonText className="outOf">{codeName(product.productStatusType, codes.ProductStatusType)}</IonText>
                  )}
                </IonCol>
              </IonRow>

              <IonRow className="review_con ion-align-self-center">
                <IonCol>
                  <IonImg src="/assets/icon/review.png" />
                  <IonText>0</IonText>
                </IonCol>
                {/* <IonCol></IonCol> */}
                {/* <IonCol>메세지 카드를 함께!</IonCol> */}
              </IonRow>
            </div>
          </IonCardContent>
        </IonRouterLink>
        <div className={`cart ${cartOn ? 'cartOn' : ''}`} onClick={() => cart_btn()}></div>
      </IonCard>
    </>
  );
};

export default ProductCard;
