/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterLink,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonText,
  SelectChangeEventDetail,
  useIonAlert,
} from '@ionic/react';
import { connect } from '../data/connect';

import './PayPage.scss';
import { RouteComponentProps } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import DefaultFooter from '../components/footer/DefaultFooter';
import routes from '../routes';
import ProductPay from '../components/product/ProductPay';
import * as selectors from '../data/selectors';
import { cartTotal, modamChangeDestination, ModamError, modamOrder, modamPayment, vbankDueDate } from '../data/dataApi';
import {
  IamportPayMethod,
  IamportResultRedirectParams,
  IamportVbankDueDate,
  Order,
  OrderPayment,
  OrderPayProductList,
  PGSetting,
  UserDestinations,
} from '../models/Pay';
import { UserDetail } from '../models/User';
import { informationCircle } from 'ionicons/icons';
import { useForm } from 'react-hook-form';
import queryString from 'query-string';

import type Iamport from 'iamport-typings';
import { setDeliveryData } from '../data/delivery/delivery.actions';
import { setCartTotal } from '../data/cart/cart.actions';
import { CodeObject } from '../data/code/code.state';

declare var IMP: Iamport;

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  pay: any;
  delivery: any;
  codes: CodeObject;
}

interface DispatchProps {
  setDeliveryData: typeof setDeliveryData;
  setCartTotal: typeof setCartTotal;
}

interface PayProps extends OwnProps, StateProps, DispatchProps {}

const PayPage: React.FC<PayProps> = ({ user, pay, setDeliveryData, delivery, setCartTotal, codes }) => {
  const [present] = useIonAlert(); // alert 폼

  const [isLoading, setIsLoading] = useState(true);
  const deliveryRef = useRef<UserDestinations | undefined>(delivery);

  const [order, setOrder] = useState<Order>(); // 주문 정보
  const [orderPayProductList, setOrderPayProductList] = useState<OrderPayProductList[]>([]); // 상품 정보
  const [userDetail, setUserDetail] = useState<UserDetail>(); // 사용자 정보
  const [userDest, setUserDest] = useState<UserDestinations>(); // 배송지 정보

  const [pgSetting, setPgSetting] = useState<PGSetting>();

  const deliveryRequestMemoList = [
    '선택 안함',
    '문 앞에 놓아주세요',
    '경비실에 맡겨주세요',
    '배송 전 연락주세요',
    '무인택배함에 넣어주세요',
    '직접 입력',
  ];
  const [requestMemoRequired, setRequestMemoRequired] = useState(false);
  const [selectedRequestMemo, setSelectedRequestMemo] = useState('');
  const [delRequestMemo, setDelRequestMemo] = useState('');

  const [chkPayment, setChkPayment] = useState(false); // 결제 체크
  const [chkPaymentType, setChkPaymentType] = useState('CREDIT_CARD'); // 결제 타입

  const [token, setToken] = useState('CREDIT_CARD'); // 첫 진입 시 토큰 저장

  const [dueDate, setDueDate] = useState<IamportVbankDueDate>(); //가상계좌 입금기한

  const initIMP = (pg?: PGSetting) => {
    setPgSetting(pg);
    IMP.init(pg?.iamportUID ?? ''); // error handle?
  };

  const vbankDue = async () => {
    const getVbankDue = await vbankDueDate();
    setDueDate(getVbankDue);
  };

  const cartTotalCount = async () => {
    const getCartTotal = await cartTotal();
    if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
  };

  const getData = async () => {
    if (pay.payCertify) {
      let accessToken = '';
      if (user.userData.constructor === Object && Object.keys(user.userData).length === 0) {
        //TODO 비회원 처리
      } else {
        accessToken = user.userData.accessToken;
      }
      setToken(accessToken);
      const result = await modamOrder(accessToken, pay.payProduct);
      setIsLoading(false);
      if (result.result) {
        setOrderPayProductList(result.orderPayProductList);
        setOrder(result.order);
        setUserDetail(result.user);
        initIMP(result.pg);

        // TODO 배송지 관련은 계속 수정예정
        if (result.user) {
          const defaultDest = result.userDest?.find((d) => d.destDefault);
          setUserDest(defaultDest);
          deliveryRef.current = defaultDest;
          setDeliveryData(defaultDest as any);
        }
      } else {
        present({
          header: '모던한담',
          message: '결제할 상품이 없습니다.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                history.back();
              },
            },
          ],
        });
        return;
      }
    } else {
      // present({
      //   header: '모던한담',
      //   message: '잘못된 접근 입니다.',
      //   buttons: [
      //     {
      //       text: '확인',
      //       role: 'cancel',
      //       cssClass: 'secondary',
      //       handler: () => {
      //         history.back();
      //       },
      //     },
      //   ],
      // });
      // return;
    }
  };

  const handleDeliveryRequest = (e: CustomEvent<SelectChangeEventDetail<any>>) => {
    setSelectedRequestMemo(e.detail.value);
    switch (e.detail.value) {
      case '문 앞에 놓아주세요':
      case '경비실에 맡겨주세요':
      case '배송 전 연락주세요':
      case '무인택배함에 넣어주세요':
        setDelRequestMemo(e.detail.value);
        setRequestMemoRequired(false);
        break;
      case '선택 안함':
        setDelRequestMemo('');
        setSelectedRequestMemo(''); // placeholder 나타나도록
        setRequestMemoRequired(false);
        break;
      case '직접 입력':
        setDelRequestMemo('');
        setRequestMemoRequired(true);
        break;
      default:
        break;
    }
  };

  const handelClickRadio = (e: any) => {
    if (e.target.value === 'CREDIT_CARD') {
      setChkPaymentType('CREDIT_CARD');
    } else if (e.target.value === 'VIRTUAL_ACCOUNT') {
      setChkPaymentType('VIRTUAL_ACCOUNT');
    }
  };

  const autoHyphen = (destRecipientCellphoneNo: string) => {
    return destRecipientCellphoneNo.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
  };

  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const {
    watch,
    register,
    setValue,
    getValues,
    // setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onTouched', //입력이 터치 될 때까지 유효성 검사.
    reValidateMode: 'onChange', //입력의 재유효성 검사.
  });

  const onSubmit = async () => {
    // TODO 벨리데이션은 추가로 작업할 예정
    setValue('orderId', order?.orderId);
    setValue('userDestination', delivery);
    setValue('paymentType', chkPaymentType);
    setValue('deliveryRequestMemo', delRequestMemo);

    // 결제 모듈 문제 걸러내기 : 0원 결제 제외
    // console.log(!!pgSetting);
    if (order?.orderGrandTotal !== 0 && !pgSetting) {
      present({
        header: '모던한담',
        message: '결제 모듈 초기화에 실패했습니다. 다시 구매 시도해 주십시오.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {},
          },
        ],
      });
      return;
    }

    const { result, ...data } = await modamPayment(token, getValues());
    if (result) {
      // TODO: 페이먼트까지는 완료
      const orderPayment = data as OrderPayment;

      // TODO 0원 결제 처리
      if (orderPayment.orderPaymentType === 'NONE' && orderPayment.paymentAmount === 0) {
        cartTotalCount(); // 장바구니 삭제 된 개수 반영
        present({
          header: '모던한담',
          message: '주문이 완료되었습니다.',
          buttons: [
            {
              text: '확인',
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                location.href = routes.MAIN;
              },
            },
          ],
        });
        return;
      }

      requestPay(orderPayment);
      return;
    } else {
      const error = data as ModamError;
      let message = error.message;
      switch (error.code) {
        case 'O0007': // 재고부족
          message = '현재 주문하신 상품 개수보다 재고가 부족하여 결제가 어렵습니다.';
          break;
        case 'O0008': // 판매상태아님
          message = '현재 판매중이 아닌 상품이 포함되어 있어 결제가 어렵습니다.';
          break;
        default:
          break;
      }
      present({
        header: '모던한담',
        message,
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              history.back();
            },
          },
        ],
      });
      return;
    }
  };

  const requestPay = (orderPayment: OrderPayment) => {
    IMP.request_pay(
      {
        // pg - tosspayments(토스신모듈), uplus(토스구모듈), html5_inicis(이니시스)
        // 연동 모듈 명시할 경우 'tosspayments.{상점아이디(MID)}'
        // 명시하지 않을 경우 대표로 설정된 것이 사용되며, 이는 곧 실결제!!!
        pg: `${pgSetting?.pgName}.${pgSetting?.pgMID}`,
        pay_method: IamportPayMethod[chkPaymentType as keyof typeof IamportPayMethod],
        merchant_uid: orderPayment.orderNo,
        name: `${orderPayProductList[0]?.productName}${orderPayProductList.length > 1 ? ` 외 ${orderPayProductList.length - 1}건` : ''}`,
        amount: orderPayment.paymentAmount,
        tax_free: order?.orderTaxFreeAmount,
        buyer_email: userDetail?.userEmail,
        buyer_name: userDetail?.userName,
        buyer_tel: userDetail?.userCellphoneNo ?? '',
        buyer_addr: `${userDetail?.userAddress} ${userDetail?.userAddressDetail}`,
        buyer_postcode: userDetail?.userZipcode,
        vbank_due: dueDate?.iamportDueDateTime,
        m_redirect_url: `${window.location.origin}${routes.PAY_COMPLETE}`,
      },
      (rsp) => {
        const imp_success = rsp.success ?? (rsp as any).imp_success;
        const params: IamportResultRedirectParams = {
          // imp_success: String(imp_success),  // deprecated
          imp_uid: rsp.imp_uid,
          merchant_uid: rsp.merchant_uid,
          orderId: orderPayment.orderId,
        };
        if (!imp_success) {
          params.error_msg = rsp.error_msg;
        }

        // m_redirect_url 로 한꺼번에 처리하기
        location.href = routes.PAY_COMPLETE + `?${queryString.stringify(params)}`;
      },
    );
  };

  useEffect(() => {
    getData();
    vbankDue();
  }, []);

  useEffect(() => {
    // console.log('ref', deliveryRef.current?.destId);
    // console.log('new', delivery?.destId);

    const updateDestination = async () => {
      let accessToken = '';
      if (user.userData.constructor === Object && Object.keys(user.userData).length === 0) {
        //TODO 비회원 처리
      } else {
        accessToken = user.userData.accessToken;
      }
      setToken(accessToken);
      const result = await modamChangeDestination(accessToken, { orderId: order!.orderId, destId: delivery?.destId });
      setIsLoading(false);
      if (result.result) {
        setOrderPayProductList(result.orderPayProductList);
        setOrder(result.order);
        // setUserDetail(result.user);
      }
    };

    // 새 주소로 변경되면 배송비 재계산
    if (deliveryRef.current?.destId !== delivery?.destId) {
      // console.log(delivery?.destAddress);
      deliveryRef.current = delivery;
      setUserDest(delivery);
      setDeliveryData(delivery);
      setIsLoading(true);
      updateDestination();
    }
  }, [delivery]);

  const chkMemo = async () => {
    if (requestMemoRequired && delRequestMemo === '') {
      present({
        header: '모던한담',
        message: '배송시 요청사항을 입력해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
    }
  };

  return (
    <IonPage id="pay_page">
      <IonContent>
        <DefaultHeader title="결제" showBackButton={true} />
        <IonGrid>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <IonAccordionGroup value="product">
              <IonAccordion value="product">
                <IonItem className="tit" slot="header">
                  <IonLabel>결제상품</IonLabel>
                </IonItem>
                {orderPayProductList.length > 0 && (
                  <>
                    <IonRow>
                      <IonCol className={'ion-text-center ion-padding-vertical'}>
                        <IonIcon icon={informationCircle} size={'large'} color="warning" />
                      </IonCol>
                    </IonRow>

                    <IonRow>
                      <IonCol className={'ion-text-center ion-padding-vertical'}>
                        <IonText>결제할 상품이 존재하지 않습니다.</IonText>
                      </IonCol>
                    </IonRow>
                  </>
                )}
                {orderPayProductList && orderPayProductList.length > 0 && (
                  <IonList slot="content" inset={false}>
                    {orderPayProductList.map((orderPayProduct) => (
                      <ProductPay orderPayProduct={orderPayProduct} codes={codes} key={orderPayProduct.productId} />
                    ))}
                  </IonList>
                )}
              </IonAccordion>
            </IonAccordionGroup>

            <IonRow>
              <IonCol>
                <div className="order_info">
                  <strong>주문고객 정보</strong>
                  <IonInput name="name" type="text" value={userDetail?.userName} readonly />
                  <IonInput name="phone" type="text" value={autoHyphen(String(userDetail?.userCellphoneNo))} readonly />
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <div className="order_info delivery">
                  <strong>배송지 정보</strong>
                  <IonRouterLink routerLink={`${routes.DELIVERY_CHANGE}`}>
                    <span>배송지 변경</span>
                  </IonRouterLink>
                  {delivery && (
                    <>
                      {userDest?.destId == delivery.destId ? (
                        <IonText>
                          <span className="d_name">{userDest?.destAddress}</span>
                          <span className="d_phone">{autoHyphen(String(userDest?.destRecipientCellphoneNo))}</span>
                          <address>{userDest?.destAddressDetail}</address>
                        </IonText>
                      ) : (
                        <IonText>
                          <span className="d_name">{delivery.destAddress}</span>
                          <span className="d_phone">{autoHyphen(String(delivery.destRecipientCellphoneNo))}</span>
                          <address>{delivery.destAddressDetail}</address>
                        </IonText>
                      )}
                    </>
                  )}
                  <IonList>
                    <IonItem>
                      <IonSelect
                        interface="action-sheet"
                        className="d_drop"
                        value={selectedRequestMemo}
                        placeholder="배송 시 요청사항을 선택해주세요."
                        cancelText="닫기"
                        onIonChange={handleDeliveryRequest}
                        onIonCancel={() => {}}
                      >
                        {deliveryRequestMemoList.map((option, i) => (
                          <IonSelectOption value={option} key={i}>
                            {option}
                          </IonSelectOption>
                        ))}
                      </IonSelect>
                    </IonItem>
                  </IonList>
                  {requestMemoRequired && (
                    <IonInput
                      id="delRequestMemo"
                      maxlength={50}
                      placeholder="수령 방법이나 요청사항을 입력해 주세요 (최대 50자)"
                      value={delRequestMemo}
                      onIonInput={(e: any) => setDelRequestMemo(e.target.value)}
                      {...register('delRequestMemo', {
                        required: true,
                      })}
                    />
                  )}
                </div>
              </IonCol>
            </IonRow>

            <IonAccordionGroup value="total_price" className="arrow_box">
              <IonAccordion value="total_price">
                <IonItem className="tit" slot="header">
                  <IonLabel>결제금액</IonLabel>
                </IonItem>

                <IonList slot="content">
                  <IonText>
                    {/*TODO 추후에 쿠폰, 포인트 적용 시 오픈*/}
                    {/*  <IonRow className="p_n">*/}
                    {/*    <IonCol>*/}
                    {/*      <div className="total_type1">*/}
                    {/*        <div className="option_box1">*/}
                    {/*          <strong>모담딜</strong>*/}
                    {/*          <p className="modam_price">*/}
                    {/*            <em>0</em>원*/}
                    {/*          </p>*/}
                    {/*        </div>*/}

                    {/*        <div className="option_box2">*/}
                    {/*          <span>무료배송</span>*/}
                    {/*          <p className="modam_price">*/}
                    {/*            <em>{order?.orderDeliveryAmount}</em>원*/}
                    {/*          </p>*/}
                    {/*        </div>*/}
                    {/*      </div>*/}
                    {/*    </IonCol>*/}
                    {/*  </IonRow>*/}

                    <IonRow className="p_n">
                      <IonCol>
                        <div className="total_type1">
                          <div className="option_box1">
                            <strong>즉시배송</strong>
                            <p className="modam_price">
                              <em>{numberWithCommas(String(order?.orderAmount))}</em>원
                            </p>
                          </div>
                          <div className="option_box1">
                            <strong>배송비</strong>
                            <p className="modam_price">
                              <em>{numberWithCommas(String(order?.orderDeliveryAmount))}</em>원
                            </p>
                          </div>

                          {order?.orderDeliveryFeeInfos
                            .sort((a, b) => a.orderDeliveryFeeInfoSeq - b.orderDeliveryFeeInfoSeq)
                            .map((info) => (
                              <div className="option_box2" key={info.orderDeliveryFeeInfoSeq}>
                                <span>{info.reason}</span>
                                <p className="modam_price">{numberWithCommas(String(info.deliveryFee))}원</p>
                              </div>
                            ))}
                        </div>
                      </IonCol>
                    </IonRow>

                    {/*<IonRow className="p_n">*/}
                    {/*  <IonCol>*/}
                    {/*    <div className="total_type1">*/}
                    {/*      <div className="option_box1">*/}
                    {/*        <strong>쿠폰</strong>*/}
                    {/*        <p className="modam_price">*/}
                    {/*          <em>0</em>원*/}
                    {/*        </p>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </IonCol>*/}
                    {/*</IonRow>*/}

                    {/*<IonRow className="p_n">*/}
                    {/*  <IonCol>*/}
                    {/*    <div className="total_type1">*/}
                    {/*      <div className="option_box1">*/}
                    {/*        <strong>포인트</strong>*/}
                    {/*        <p className="modam_price">*/}
                    {/*          <em>0</em>원*/}
                    {/*        </p>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*  </IonCol>*/}
                    {/*</IonRow>*/}

                    <IonRow className="p_n b_l">
                      <IonCol>
                        <div className="total_type1 type2">
                          <div className="option_box1">
                            <strong>최종 결제금액</strong>
                            <p className="modam_price">
                              <em>{numberWithCommas(String(order?.orderGrandTotal))}</em>원
                            </p>
                          </div>
                        </div>
                      </IonCol>
                    </IonRow>
                  </IonText>
                </IonList>
              </IonAccordion>
            </IonAccordionGroup>

            <IonRow>
              <IonCol>
                <div className="order_info">
                  {!!order && order.orderAmount > 0 && (
                    <>
                      <strong>결제 수단</strong>
                      <div className="radio_box">
                        <input
                          type="radio"
                          name="pay"
                          id="pay_type1"
                          value="CREDIT_CARD"
                          checked={chkPaymentType == 'CREDIT_CARD'}
                          onChange={handelClickRadio}
                        />
                        <label htmlFor="pay_type1" className="pay_tit">
                          신용카드·체크카드
                        </label>
                      </div>

                      <div className="radio_box">
                        <input
                          type="radio"
                          name="pay"
                          id="pay_type2"
                          value="VIRTUAL_ACCOUNT"
                          checked={chkPaymentType == 'VIRTUAL_ACCOUNT'}
                          onChange={handelClickRadio}
                        />
                        <label htmlFor="pay_type2" className="pay_tit">
                          무통장입금(가상계좌)
                        </label>
                      </div>
                      {chkPaymentType === 'VIRTUAL_ACCOUNT' && (
                        <div className="virtual_info_box">
                          <p className="virtual_due">
                            <em>입금 마감일 : {dueDate?.dueDate}</em>
                          </p>
                          <p className="virtual_info">
                            <li>입금 마감일까지 입금확인이 되지 않을 경우 주문은 자동 취소됩니다.</li>
                            <li>입금 시 예금주 명은 "모던한담"으로 확인됩니다.</li>
                            <li>입금자명과 주문 고객 정보가 일치하지 않아도 금액이 일치할 경우 정상 입금 처리됩니다.</li>
                            <li>은행 이체 수수료가 발생할 수 있습니다. 입금 시 수수료를 꼭 확인 부탁드립니다.</li>
                          </p>
                        </div>
                      )}
                      {/* TODO : 네이버 페이 추후 작업*/}
                      {/*<div className="radio_box">*/}
                      {/*  <input type="radio" name="pay" id="pay_type3" />*/}
                      {/*  <label htmlFor="pay_type3" className="pay_tit">*/}
                      {/*    네이버페이*/}
                      {/*  </label>*/}
                      {/*</div>*/}
                    </>
                  )}
                </div>

                <div className="submit_wrap">
                  <IonButton
                    className="submit"
                    type="submit"
                    expand="full"
                    disabled={isLoading || order?.orderStatusType === 'UNAVAILABLE'}
                    onClick={() => chkMemo()}
                  >
                    {isLoading ? (
                      '계산중...'
                    ) : order?.orderStatusType !== 'UNAVAILABLE' ? (
                      <>
                        <em>{numberWithCommas(String(order?.orderGrandTotal))}</em>원 결제하기
                      </>
                    ) : (
                      '구매 불가능한 상품이 있습니다'
                    )}
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </form>
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    user: selectors.getUser(state),
    pay: selectors.getPay(state),
    delivery: selectors.getDelivery(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setDeliveryData, setCartTotal },
  component: PayPage,
});
