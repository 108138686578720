import { Storage } from '@capacitor/storage';
import { URL_PREFIX } from '../constants';
import { FieldValues, UnpackNestedValue } from 'react-hook-form';
import { Notice } from '../models/Notice';
import { Category } from '../models/Category';
import { Product } from '../models/Product';
import {
  IamportResultRedirectParams,
  OrderPayment,
  OrderPayProductList,
  PaymentVerificationResult,
  PGSetting,
  UserDestinations,
} from '../models/Pay';
import { OAuthSignUpData } from '../models/User';
import { Delivery } from '../models/Delivery';
import { Order, OrderCancelRequest, OrderList } from '../models/OrderDetail';
import { Cart } from '../models/Cart';
import { AgreementContent } from '../models/Agreements';
import { CodeObject } from './code/code.state';

const ACCESS_TOKEN = 'accessToken';
const HAS_LOGGED_IN = 'isLoggedIn';
const USERNAME = 'userName';
const USERID = 'userId';
const SAVE_ID = 'save_id';
const SAVE_CHK = 'save_chk';
const SOCIAL_STATUS = 'social_status';
const SOCIAL_TYPE = 'social_type';
const SOCIAL_ID = 'social_userId';
const SOCIAL_NAME = 'social_userName';
const SOCIAL_PHONE = 'social_phone';
const SOCIAL_EMAIL = 'social_email';
const CATEGORY_ID = 'category_id';
const CURRENT_CATEGORY_ID = 'current_category_id';
const CART_COUNT = 'cart_count';
const PAY = 'pay_data';
const PAY_PRODUCT = 'pay_product';

export interface ModamError {
  errorTime: string;
  message: string;
  code: string;
}

export const withCredentials: RequestInit = {
  mode: 'cors',
  credentials: 'include',
};

/**
 * enum codes
 */
export const codeState = async () => {
  const response = await fetch(`${URL_PREFIX}/api/v1/codes`, {
    method: 'GET',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.ok) {
    const codes = (await response.json()) as CodeObject;
    return codes;
  }
  return Promise.reject(response.statusText);
};

/**
 * 소셜 로그인 후 모던한담 로그인 토큰 인증 API
 */
export const socialModamLogin = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/oauth2/authenticate`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token: accessToken,
    }),
  });

  const responseData = await response.json();
  if (responseData.token) {
    return {
      isLoggedIn: true,
      userName: responseData.name,
      accessToken: responseData.token,
    };
  } else {
    return {
      isLoggedIn: false,
      message: responseData.message,
    };
  }
};

/**
 * 소셜 로그인으로 회원가입 시 취득한 데이터를 받아오기 위한 API
 */
export const socialModamSignUp = async (code: string): Promise<OAuthSignUpData | null> => {
  const response = await fetch(`${URL_PREFIX}/api/oauth2/signup?code=${code}`, {
    method: 'GET',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const { code: responseCode, ...responseData } = await response.json();
  if (response.ok && responseCode) {
    return responseData;
  } else {
    return null;
  }
};

/**
 * 소셜 로그인 API
 */
//TODO : 리다이렉션 안하고 cors 정보를 받을 수 있는 방법을 찾...
export const socialLogin = async (value: string) => {
  const response = await fetch(`${URL_PREFIX}/api/oauth2/authorize/` + value, {
    method: 'GET',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return {
    url: response.url,
  };
};

/**
 * 로그인 API
 */
export const doLogin = async (username: string, password: string, checked: boolean) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/login`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });

  const responseData = await response.json();
  if (responseData.token) {
    if (checked) {
      await setSaveIdBox(true);
      await setSaveId(username);
    } else {
      await setSaveId(undefined);
      await setSaveIdBox(false);
    }

    await setUserId(username);

    return {
      isLoggedIn: true,
      userName: responseData.name,
      accessToken: responseData.token,
      userId: username,
    };
  } else {
    return {
      isLoggedIn: false,
    };
  }
};

/**
 * 로그아웃 API
 */
export const doLogout = async () => {
  await fetch(`${URL_PREFIX}/api/v1/logout`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return {
    isLoggedIn: false,
  };
};

/**
 * 내정보 관리 패스워드 체크
 */
export const myPagePwChk = async (accessToken: string, password: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/check-user-password`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      oldPassword: password,
    }),
  });

  return {
    isLoggedIn: response.status === 200,
  };
};

export const checkCertification = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/check-user-certification`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  const responseData = await response.json();

  if (responseData.verify) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
      ...responseData,
    };
  }
};

/**
 * 마이페이지 내정보관리 SNS 로그인 확인
 */
export const myPageSnsChk = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/check-user-password`, {
    method: 'POST',
    cache: 'no-cache',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({}),
  });

  const responseData = await response.json();

  if (responseData.verify) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
      ...responseData,
    };
  }
};

/**
 * 회원 가입 API
 */
export const signupUser = async (data: UnpackNestedValue<FieldValues>) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/sign-up`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 회원 상세 API
 */
export const getDetailUser = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/detail`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      Authorization: accessToken,
    },
  });

  const status = response.status;
  const responseData = await response.json();
  if (status === 200) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 회원 수정 API
 */
export const putUpdateUser = async (accessToken: string, data: UnpackNestedValue<FieldValues>) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/detail`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 회원 탈퇴 API
 */
export const deleteUser = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/withdraw`, {
    method: 'DELETE',
    ...withCredentials,
    headers: {
      Authorization: accessToken,
    },
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 휴대전화 인증번호 발급 API
 */
export const phoneCertify = async (userCellphoneNo: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/cert/phone/sign-up`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userCellphoneNo: userCellphoneNo,
    }),
  });

  const responseData = await response.json();
  if (responseData.certSeq) {
    return {
      result: true,
      certSeq: responseData.certSeq,
    };
  } else {
    return {
      result: false,
      certSeq: 0,
    };
  }
};

/**
 * 이메일 중복 확인 API
 * @param email
 */
export const emailCheck = async (email: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/check-user`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      userEmail: email,
    }),
  });

  const isExist = await response.json();
  console.log(isExist);
  return isExist;
};

/**
 * 휴대전화 인증번호 확인 API
 */
export const verification = async (codeSeq: number, code: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/cert/phone/sign-up`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      certSeq: codeSeq,
      certNo: code,
    }),
  });

  //인증에 성공하면 status 200으로 체크
  if (response.status == 200) {
    return {
      result: true,
      code: '',
      message: '',
    };
  } else {
    const responseData = await response.json();
    if (responseData != null && responseData) {
      return {
        result: false,
        code: responseData.code,
        message: responseData.message,
      };
    } else {
      return {
        result: false,
        code: 'error',
        message: '다시 시도해주세요.',
      };
    }
  }
};

/**
 * 이용약관 필수 동의 API
 */
export const agreementA = async (agreeType: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/users/agreements`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      agreeType: agreeType,
    }),
  });

  const responseData = await response.json();
  if (responseData != null && responseData) {
    return {
      responseData,
    };
  } else {
    return {
      result: false,
      code: 'error',
      message: '다시 시도해주세요.',
    };
  }
};

/**
 * 동의서 자세히 보기 (상세) API
 */
export const agreementB = async (type: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/agreement-contents/` + type, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseData: AgreementContent = await response.json();
  if (responseData != null && responseData) {
    return {
      result: true,
      agreement: responseData,
    };
  } else {
    return {
      result: false,
      code: 'error',
      message: '다시 시도해주세요.',
    };
  }
};

/**
 * 공지사항 조회
 */
export const noticeSelect = async (
  options: {
    page?: number;
    size?: number;
  } = {
    page: 1,
    size: 10,
  },
) => {
  const limit = options.page ? `&page=${options.page}` : '&page=1';
  const offset = options.size ? `&size=${options.size}` : '&size=10';
  const url = `${URL_PREFIX}/api/v1/notices?${limit}${offset}`;

  const response = await fetch(url, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  if (responseData != null && responseData.totalElements > 0) {
    const notice = responseData.content as Notice[];

    return {
      result: true,
      totalCount: responseData.totalElements,
      notice,
    };
  } else {
    return {
      result: false,
      code: response.status,
      totalCount: 0,
      notice: [],
    };
  }
};

/**
 * 공지사항 조회수 및 상세
 */
export const noticeCount = async (key: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/notices/` + key, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  if (responseData != null && responseData) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 모던한담 상품 API
 */
export const productSelect = async (
  options: {
    category?: number;
    page?: number;
    size?: number;
  } = {
    category: 1,
    page: 1,
    size: 10,
  },
) => {
  const categoryId = options.category ? `categoryId=${options.category}` : 'categoryId=1';
  const limit = options.page ? `&page=${options.page}` : '&page=1';
  const offset = options.size ? `&size=${options.size}` : '&size=10';
  const url = `${URL_PREFIX}/api/v1/products?${categoryId}${limit}${offset}`;

  const response = await fetch(url, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  if (responseData != null && responseData.totalElements > 0) {
    const product = responseData.content as Product[];

    return {
      result: true,
      totalCount: responseData.totalElements,
      product,
    };
  } else {
    return {
      result: false,
      code: response.status,
      totalCount: 0,
      product: [],
    };
  }
};

/**
 * 장바구니 갯수 API
 */
export const cartTotal = async () => {
  const response = await fetch(`${URL_PREFIX}/api/v1/cart/count-cart-product`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  if (responseData != null) {
    return {
      result: true,
      cartCount: responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 장바구니 조회 API
 */
export const cartSelect = async (accessToken: string) => {
  console.log('accessToken : ', accessToken);
  //TODO 테스트 좀 더 필요 현재 결제 테스트 중이라 임시로 토큰 뺌
  const response = await fetch(`${URL_PREFIX}/api/v1/cart/list`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData: Cart[] = await response.json();
  if (responseData != null && responseData.length > 0) {
    return {
      result: true,
      responseData: responseData,
    };
  } else {
    return {
      result: false,
      responseData: [],
    };
  }
};

/**
 * 장바구니 주문수량 변경 API
 */
export const cartChangeQty = async (productId: number, qty: number, id: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/cart/change-quantity`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      productId: productId,
      cartQuantity: qty,
      cartId: id,
    }),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 장바구니 담기 API
 */
export const cartInsert = async (id: number, orderQty: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/cart/add-cart-product`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },

    body: JSON.stringify({
      productId: id,
      cartQuantity: orderQty,
    }),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
      count: await response.json(),
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 장바구니 삭제 API
 */
export const cartRemove = async (id: any[]) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/cart/remove-cart-product`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(id),
  });
  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 상품 카테고리 API
 */
export const getCategory = async () => {
  const response = await fetch(`${URL_PREFIX}/api/v1/products/categories`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();

  if (responseData != null) {
    const category = responseData as Category[];
    return {
      result: true,
      category,
    };
  } else {
    return {
      result: false,
      category: [],
    };
  }
};

/**
 * 상품 상세 API
 **/
export const getProductDetail = async (id: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/products/${id}`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const responseData = await response.json();
  if (responseData != null) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

export const modamOrderList = async (accessToken: string, query: any): Promise<Array<OrderList>> => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders?page=${query.page}&size=${query.size}`, {
    method: 'GET',
    ...withCredentials,
    cache: 'no-cache',
    headers: {
      Authorization: accessToken,
    },
  });

  const responseData = await response.json();

  return responseData;
};

export const modamCancelOrder = async (accessToken: string, orderId: number, cancelInfo: OrderCancelRequest) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders/${orderId}/cancel`, {
    method: 'POST',
    ...withCredentials,
    cache: 'no-cache',
    headers: {
      Authorization: accessToken,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(cancelInfo),
  });

  const responseData = await response.json();
  if (response.ok) {
    return {
      result: true,
      status: responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

export const modamDecideOrder = async (accessToken: string, orderId: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders/decided?orderId=${orderId}`, {
    method: 'PATCH',
    ...withCredentials,
    cache: 'no-cache',
    headers: {
      Authorization: accessToken,
    },
  });

  return response.status === 200;
};

/**
 * 주문 API
 */
export const modamOrder = async (accessToken: string, payProduct: any) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },

    body: JSON.stringify(payProduct),
  });

  const responseData = await response.json();
  if (responseData != null && responseData.orderProductList.length > 0) {
    const orderPayProductList = responseData.orderProductList as OrderPayProductList[];
    return {
      result: true,
      orderPayProductList,
      order: responseData.order,
      user: responseData.user,
      userDest: responseData.user.userDestinations as UserDestinations[],
      pg: responseData.pgSetting as PGSetting,
    };
  } else {
    return {
      result: false,
      orderPayProductList: [],
      order: null,
      user: null,
    };
  }
};

export const modamChangeDestination = async (accessToken: string, newDestination: { orderId: number; destId: number }) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders/change-destination`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },

    body: JSON.stringify(newDestination),
  });

  const responseData = await response.json();
  if (responseData != null && responseData.orderProductList.length > 0) {
    const orderPayProductList = responseData.orderProductList as OrderPayProductList[];
    return {
      result: true,
      orderPayProductList,
      order: responseData.order,
      user: responseData.user,
      userDest: responseData.user.userDestinations as UserDestinations[],
    };
  } else {
    return {
      result: false,
      orderPayProductList: [],
      order: null,
      user: null,
    };
  }
};

/**
 * 주문 상세 API
 */
export const modamOrderDetail = async (accessToken: string, orderId: string): Promise<Order> => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders/${orderId}`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  const responseData = (await response.json()) as Order;
  return {
    ...responseData,
  };
};

/**
 * 결제 API
 */
export const modamPayment = async (accessToken: string, payment: UnpackNestedValue<FieldValues>) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/orders/payment`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },

    body: JSON.stringify(payment),
  });

  const responseData = await response.json();
  return {
    result: response.ok,
    ...responseData,
  };
};

/**
 * 배송지 조회 API
 **/
export const deliverySelect = async (accessToken: string) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      Authorization: accessToken,
    },
  });

  const responseData = await response.json();
  const delivery = responseData as Delivery[];
  if (responseData != null) {
    return {
      result: true,
      delivery,
    };
  } else {
    return {
      result: false,
      delivery: [],
    };
  }
};

/**
 * 배송지 추가 API
 **/
export const deliveryCreate = async (accessToken: string, data: UnpackNestedValue<FieldValues>) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  });

  if (response != null && response.status == 201) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 배송지 상세 API
 **/
export const getDeliveryDetail = async (accessToken: string, id: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination/${id}`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  const responseData = await response.json();
  if (responseData != null) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 배송지 수정 API
 **/
export const deliveryUpdate = async (accessToken: string, data: UnpackNestedValue<FieldValues>) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 배송지 삭제 API
 **/
export const deliveryRemove = async (accessToken: string, id: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination/delete`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      destId: id,
    }),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

/**
 * 배송지 기본배송지 선택 API
 **/
export const deliveryDefault = async (accessToken: string, destId: number) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/user-destination/default`, {
    method: 'PUT',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
    body: JSON.stringify({
      destId: destId,
    }),
  });

  if (response != null && response.status == 200) {
    return {
      result: true,
    };
  } else {
    return {
      result: false,
    };
  }
};

export const verifyPayment = async (paymentResult: IamportResultRedirectParams) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/iamport/complete`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      // Authorization: accessToken,
    },

    body: JSON.stringify(paymentResult),
  });

  const responseData = (await response.json()) as PaymentVerificationResult;
  if (response.ok && responseData != null) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};

export const sendPaymentErrorLog = async (paymentResult: IamportResultRedirectParams) => {
  const response = await fetch(`${URL_PREFIX}/api/v1/iamport/fail`, {
    method: 'POST',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
      // Authorization: accessToken,
    },

    body: JSON.stringify(paymentResult),
  });

  // 결과 사용 안함
};

/**
 * 가상계좌 입금 기한
 **/

export const vbankDueDate = async () => {
  const response = await fetch(`${URL_PREFIX}/api/v1/iamport/vbankdue`, {
    method: 'GET',
    ...withCredentials,
    headers: {
      'Content-Type': 'application/json',
    },
  });
  const responseData = await response.json();
  if (responseData != null && responseData) {
    return {
      result: true,
      ...responseData,
    };
  } else {
    return {
      result: false,
    };
  }
};
export const getUserData = async () => {
  const response = await Promise.all([
    Storage.get({ key: HAS_LOGGED_IN }),
    Storage.get({ key: USERNAME }),
    Storage.get({ key: ACCESS_TOKEN }),
    Storage.get({ key: USERID }),
  ]);
  const isLoggedIn = (await response[0].value) === 'true';
  const userName = (await response[1].value) || '';
  const accessToken = (await response[2].value) || '';
  const userId = (await response[3].value) || '';
  const data = {
    isLoggedIn,
    userName,
    accessToken,
    userId,
  };
  return data;
};

export const getSocialSignUpData = async () => {
  const response = await Promise.all([
    Storage.get({ key: SOCIAL_STATUS }),
    Storage.get({ key: SOCIAL_TYPE }),
    Storage.get({ key: SOCIAL_EMAIL }),
    Storage.get({ key: SOCIAL_ID }),
    Storage.get({ key: SOCIAL_NAME }),
    Storage.get({ key: SOCIAL_PHONE }),
  ]);
  const status = (await response[0].value) || '';
  const type = (await response[1].value) || '';
  const email = (await response[2].value) || '';
  const userId = (await response[3].value) || '';
  const userName = (await response[4].value) || '';
  const phone = (await response[5].value) || '';
  const data = {
    status,
    type,
    email,
    userId,
    userName,
    phone,
  };
  return data;
};

export const getPayData = async () => {
  const response = await Promise.all([Storage.get({ key: PAY }), Storage.get({ key: PAY_PRODUCT })]);
  const payCertify = (await response[0].value) || '';
  let payProduct = (await response[1].value) || '';
  if (!payProduct) {
    payProduct = '';
  } else {
    payProduct = JSON.parse(payProduct);
  }
  const data = {
    payCertify,
    payProduct,
  };
  return data;
};

export const getCartData = async () => {
  const response = await Promise.all([Storage.get({ key: CART_COUNT })]);
  const cartCount = (await response[0].value) || '';
  const data = {
    cartCount,
  };
  return data;
};

export const getCategoryData = async () => {
  const response = await Promise.all([Storage.get({ key: CATEGORY_ID })]);
  const categoryId = (await response[0].value) || '';
  const data = {
    categoryId,
  };
  return data;
};

//get
export const getSaveId = async () => {
  const save_id = await Storage.get({ key: SAVE_ID });
  return save_id.value !== 'null' ? save_id.value : null;
};
export const getSaveBox = async () => {
  const save_chk = await Storage.get({ key: SAVE_CHK });
  return save_chk.value !== 'null' ? save_chk.value : null;
};

export const getUserId = async () => {
  const userId = await Storage.get({ key: USERID });
  return userId.value !== 'null' ? userId.value : null;
};

//set
export const setIsLoggedInData = async (isLoggedIn: boolean) => {
  await Storage.set({ key: HAS_LOGGED_IN, value: JSON.stringify(isLoggedIn) });
};

export const setUserNameData = async (username?: string) => {
  if (!username) {
    await Storage.remove({ key: USERNAME });
  } else {
    await Storage.set({ key: USERNAME, value: username });
  }
};

export const setAccessToken = async (accessToken?: string) => {
  if (!accessToken) {
    await Storage.remove({ key: ACCESS_TOKEN });
  } else {
    await Storage.set({ key: ACCESS_TOKEN, value: accessToken });
  }
};

export const setUserId = async (userId?: string) => {
  if (!userId) {
    await Storage.remove({ key: USERID });
  } else {
    await Storage.set({ key: USERID, value: userId });
  }
};

export const setSaveId = async (saveId?: string) => {
  if (!saveId) {
    await Storage.remove({ key: SAVE_ID });
  } else {
    await Storage.set({ key: SAVE_ID, value: saveId });
  }
};

export const setSaveIdBox = async (saveId?: boolean) => {
  if (!saveId) {
    await Storage.remove({ key: SAVE_CHK });
  } else {
    await Storage.set({ key: SAVE_CHK, value: JSON.stringify(saveId) });
  }
};

export const setSocialStatusData = async (socialStatus?: string) => {
  if (!socialStatus) {
    await Storage.remove({ key: SOCIAL_STATUS });
  } else {
    await Storage.set({ key: SOCIAL_STATUS, value: socialStatus });
  }
};

export const setSocialTypeData = async (socialType?: string) => {
  if (!socialType) {
    await Storage.remove({ key: SOCIAL_TYPE });
  } else {
    await Storage.set({ key: SOCIAL_TYPE, value: socialType });
  }
};

export const setSocialIdData = async (socialId?: string) => {
  if (!socialId) {
    await Storage.remove({ key: SOCIAL_ID });
  } else {
    await Storage.set({ key: SOCIAL_ID, value: socialId });
  }
};

export const setSocialUserNameData = async (socialUsername?: string) => {
  if (!socialUsername) {
    await Storage.remove({ key: SOCIAL_NAME });
  } else {
    await Storage.set({ key: SOCIAL_NAME, value: socialUsername });
  }
};

export const setSocialPhoneNumberData = async (socialPhone?: string) => {
  if (!socialPhone) {
    await Storage.remove({ key: SOCIAL_PHONE });
  } else {
    await Storage.set({ key: SOCIAL_PHONE, value: socialPhone });
  }
};

export const setSocialEmailData = async (socialEmail?: string) => {
  if (!socialEmail) {
    await Storage.remove({ key: SOCIAL_EMAIL });
  } else {
    await Storage.set({ key: SOCIAL_EMAIL, value: socialEmail });
  }
};

export const setCartCountData = async (cartCount?: string) => {
  if (!cartCount) {
    await Storage.remove({ key: CART_COUNT });
  } else {
    await Storage.set({ key: CART_COUNT, value: cartCount });
  }
};

export const setCategoryIdData = async (categoryId?: string) => {
  if (!categoryId) {
    await Storage.remove({ key: CATEGORY_ID });
  } else {
    await Storage.set({ key: CATEGORY_ID, value: categoryId });
  }
};

export const setCurrentCategoryIdData = async (categoryId?: string) => {
  if (!categoryId) {
    await Storage.remove({ key: CURRENT_CATEGORY_ID });
  } else {
    await Storage.set({ key: CURRENT_CATEGORY_ID, value: categoryId });
  }
};

export const setPayCertifyData = async (payCertify: boolean) => {
  await Storage.set({ key: PAY, value: JSON.stringify(payCertify) });
};

export const setPayCertifyProduct = async (payProduct?: any) => {
  if (!payProduct) {
    await Storage.remove({ key: PAY_PRODUCT });
  } else {
    await Storage.set({ key: PAY_PRODUCT, value: JSON.stringify(payProduct) });
  }
};
