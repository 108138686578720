/* eslint-disable react-hooks/exhaustive-deps,@typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { IonButton, IonCol, IonContent, IonGrid, IonIcon, IonList, IonPage, IonRow, IonText, useIonAlert, useIonToast } from '@ionic/react';
import { connect } from '../data/connect';

import { RouteComponentProps, useLocation } from 'react-router';
import DefaultHeader from '../components/header/DefaultHeader';
import './MyCartPage.scss';
import DefaultFooter from '../components/footer/DefaultFooter';
import MyCartCard from '../components/cart/MyCartCard';
import { Cart } from '../models/Cart';
import { cartRemove, cartSelect, cartTotal } from '../data/dataApi';
import routes from '../routes';
import { bagHandleOutline } from 'ionicons/icons';
import * as selectors from '../data/selectors';
import { setCartTotal } from '../data/cart/cart.actions';
import { setPayCertify, setPayProduct } from '../data/pay/pay.actions';
import { CodeObject } from '../data/code/code.state';
import { ProductStatusType } from '../models/Product';
import { PayProduct } from '../data/pay/pay.state';

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  user: any;
  category: any;
  codes: CodeObject;
}

interface DispatchProps {
  setCartTotal: typeof setCartTotal;
  setPayCertify: typeof setPayCertify;
  setPayProduct: typeof setPayProduct;
}

interface CartProps extends OwnProps, StateProps, DispatchProps {}

const MyCartPage: React.FC<CartProps> = ({ match, category, setCartTotal, user, history, setPayCertify, setPayProduct, codes }) => {
  const [present] = useIonAlert(); // alert 폼
  const [cartRemoveChk, setCartRemoveChk] = useState(false); // 장바구니 컴포넌트 삭제여부
  const [carts, setCarts] = useState<Cart[]>([]); // 장바구니 리스트
  const [chkCartData, setChkCartData] = useState(true); // 장바구니 리스트 체크

  const [cartCount, setCartCount] = useState(0); // 장바구니 카운트 (상품 갯수 설정) props
  const [productId, setProductId] = useState(0); // 장바구니 아아디 props
  const [productPrice, setProductPrice] = useState(0); // 상품 가격 props

  const [modamPrice, setModamPrice] = useState(0); // 모담딜 총 가격
  const [modamDeliveryPrice, setModamDeliveryPrice] = useState(0); // 모담딜 - 무료배송 (가격란)

  const [immediatelyPrice, setImmediatelyPrice] = useState(0); // 즉시배송 총 가격
  const [deliveryPrice, setDeliveryPrice] = useState(0); // 즉시배송 - 배송비 (5만원 이상 무료배송)

  const [totalPrice, setTotalPrice] = useState(0); // 총 결제 금액

  const [checkItems, setCheckItems] = useState<number[]>([]); // 체크박스 리스트
  const [url, setUrl] = useState(''); // 쇼핑 계속하기 URL

  const defaultPayProducts: PayProduct = {
    products: [],
    fromCart: true,
  };

  const getData = async () => {
    let accessToken = '';
    if (user.userData.constructor === Object && Object.keys(user.userData).length === 0) {
      //TODO 비회원 처리
    } else {
      accessToken = user.userData.accessToken;
    }
    const data = await cartSelect(accessToken);
    if (data.responseData.length > 0) {
      //TODO : 장바구니 갯수 제한을 두던가, 갯수 페이징이 필요할 수 있음
      //TODO : 모담딜, 즉시배송 및 작업 해야됨.
      setCarts(data.responseData);

      // 기본값: 전부 선택
      setCheckItems(data.responseData.filter((c: Cart) => c.productStatusType === ProductStatusType.SALE).map((c) => c.productId));

      setChkCartData(true);

      cartPriceWrap(data.responseData, true);
    } else {
      setChkCartData(false);
    }
  };

  const cartPriceWrap = (carts: Cart[], resetList: boolean = false, changeCount: number = 0, changeId: number = 0) => {
    const totalPriceArray: any = [];
    let total = 0;
    console.log(carts);
    carts
      .filter((cart) => checkItems.includes(cart.productId))
      .forEach((cart: { productSalePrice: number; cartQuantity: number; productId: number }) => {
        if (!resetList) {
          if (cart.productId == changeId) {
            cart.cartQuantity = changeCount;
          }
        }
        total += cart.productSalePrice * cart.cartQuantity;
        //TODO : 배송비 추가 시 작업
        totalPriceArray.push(cart.productSalePrice * cart.cartQuantity);
      });

    setImmediatelyPrice(total);
  };

  const handleSingleCheck = (checked: boolean, id: number) => {
    if (checked) {
      // 'TS2322' 변경예정 Type 'string' is not assignable to type 'never'
      // @ts-ignore
      setCheckItems([...checkItems, id]);
    } else {
      setCheckItems(checkItems.filter((item) => item !== id));
    }
  };

  const handleAllCheck = (checked: boolean) => {
    if (checked) {
      const cartIdArray: number[] = [];
      carts.filter((cart) => cart.productStatusType === ProductStatusType.SALE).forEach((cart) => cartIdArray.push(cart.productId));
      setCheckItems(cartIdArray);
    } else {
      setCheckItems([]);
    }
  };

  const removeCart = async () => {
    if (checkItems.length > 0) {
      const items: any = [];
      checkItems.map((item, index) => {
        let el = { productId: item, cartId: carts[index].cartId };
        items.push(el);
      });

      present({
        header: '모던한담',
        message: '선택하신 ' + items.length + '개 상품을 장바구니에서 삭제하시겠습니까?',
        buttons: [
          {
            text: '취소',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              return;
            },
          },
          {
            text: '확인',
            handler: async () => {
              const removeCart = await cartRemove(items);
              if (removeCart.result) {
                setCartRemoveChk(true);
                setCheckItems([]);
                const getCartTotal = await cartTotal();
                if (getCartTotal.result) setCartTotal(getCartTotal.cartCount);
              } else {
                present({
                  header: '모던한담',
                  message: '다시 시도해 주세요.',
                  buttons: [
                    {
                      text: '확인',
                      role: 'cancel',
                      cssClass: 'secondary',
                    },
                  ],
                });
                return;
              }
            },
          },
        ],
      });
    } else {
      present({
        header: '모던한담',
        message: '삭제 항목을 선택해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  const orderSubmit = async () => {
    // TODO 비회원 결제 로직 완성 후 아래 내용 제거할 것
    if (!user.userData.isLoggedIn) {
      present({
        header: '모던한담',
        message: '주문은 로그인 후 사용가능합니다.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              history.push(routes.LOGIN);
            },
          },
        ],
      });

      return;
    }

    if (totalPrice > 0) {
      const items: any = [];
      carts
        .filter((item) => checkItems.includes(item.productId))
        .map((item, index) => {
          if (item.cartQuantity > 0) {
            let el = { productId: item.productId, cartQuantity: item.cartQuantity };
            items.push(el);
          }
        });
      setPayCertify(true);
      setPayProduct({
        ...defaultPayProducts,
        products: items,
      });
      history.push(routes.PAY);
    } else {
      setPayCertify(false);
      setPayProduct(defaultPayProducts);
      present({
        header: '모던한담',
        message: '결제할 상품을 선택해주세요.',
        buttons: [
          {
            text: '확인',
            role: 'cancel',
            cssClass: 'secondary',
          },
        ],
      });
      return;
    }
  };

  function numberWithCommas(x: { toString: () => string }) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  // useEffect(() => {
  // setImmediatelyPrice(productPrice * cartCount);
  // setTotalPrice(immediatelyPrice + modamPrice);
  // console.log('productPrice : ', productPrice);
  // }, [modamPrice, immediatelyPrice, cartCount]);

  useEffect(() => {
    getData();
    if (cartRemoveChk) {
      setCartRemoveChk(false);
    }
  }, [match.params, cartRemoveChk]);

  useEffect(() => {
    if (productId > 0) {
      cartPriceWrap(carts, false, cartCount, productId);
    }
    setTotalPrice(immediatelyPrice + modamPrice);
  }, [carts, immediatelyPrice, cartCount, productId]);

  useEffect(() => {
    setUrl(`${routes.PRODUCT}` + '?categoryId=' + category.categoryId);
  }, [category]);

  useEffect(() => {
    cartPriceWrap(carts, false, cartCount, productId);
  }, [checkItems]);

  return (
    <IonPage id="cart">
      <IonContent>
        <DefaultHeader title="장바구니" showBackButton={true} />
        <IonGrid>
          {chkCartData && (
            <>
              <IonRow className="cart_type1">
                <IonCol>
                  <div className="all_wrap">
                    <input
                      type="checkbox"
                      id="allChecked"
                      onChange={(e) => handleAllCheck(e.target.checked)}
                      checked={checkItems.length === carts.filter((cart) => cart.productStatusType === ProductStatusType.SALE).length}
                    />
                    <label htmlFor="allChecked"> 전체선택</label>
                  </div>
                </IonCol>

                <IonCol className="check_remove_wrap">
                  <button type="button" onClick={() => removeCart()}>
                    선택삭제
                  </button>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  {/*TODO : 현재 모담딜, 즉시배송 등 나누지 않음 임시 주석*/}
                  {/*<div className="cart_tit">*/}
                  {/*  <strong>모담딜</strong>*/}
                  {/*  <span>8월 24일 (화) 발송</span>*/}
                  {/*</div>*/}

                  {carts && carts.length > 0 && (
                    <IonList inset={false}>
                      {carts.map((cart, index) => (
                        <MyCartCard
                          key={cart.cartSeq}
                          codes={codes}
                          cart={cart}
                          cartRemoveChk={(cartRemoveChk) => {
                            setCartRemoveChk(cartRemoveChk);
                          }}
                          handleSingleCheck={handleSingleCheck}
                          checkItems={checkItems}
                          cartQty={(cartCount, productId) => {
                            setCartCount(cartCount);
                            setProductId(productId);
                          }}
                          productPrice={setProductPrice}
                          setCartTotal={setCartTotal}
                        />
                      ))}
                    </IonList>
                  )}
                </IonCol>
              </IonRow>

              {/*TODO:MyCartCard 컴포넌트 사용*/}
              {/*<IonRow>*/}
              {/*  <IonCol>*/}
              {/*    <div className="cart_tit">*/}
              {/*      <strong>즉시배송</strong>*/}
              {/*      <span>지금 주문하면 오늘 발송</span>*/}
              {/*    </div>*/}
              {/*    <IonList inset={false}>*/}
              {/*      {carts && carts.length > 0 && (*/}
              {/*        <IonList inset={false}>*/}
              {/*          {carts.map((cart) => (*/}
              {/*            <MyCartCard cart={cart} key={cart.id} />*/}
              {/*          ))}*/}
              {/*        </IonList>*/}
              {/*      )}*/}
              {/*    </IonList>*/}
              {/*  </IonCol>*/}
              {/*</IonRow>*/}

              <IonRow className="pt">
                <IonCol>
                  <div className="total_type1">
                    <div className="option_box1">
                      <strong>모담딜</strong>
                      <p className="modam_price">
                        <em>0</em>원
                      </p>
                    </div>

                    {/* <div className="option_box2">
                      <span>무료배송</span>
                      <p className="modam_price">
                        <em>0</em>원
                      </p>
                    </div> */}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="total_type1">
                    <div className="option_box1">
                      <strong>즉시배송</strong>
                      <p className="modam_price">
                        <em>{numberWithCommas(immediatelyPrice)}</em>원
                      </p>
                    </div>

                    {/* <div className="option_box2">
                      <span>배송비(5만원 이상 무료 배송)</span>
                      <p className="modam_price">
                        <em>0</em>원
                      </p>
                    </div> */}
                  </div>
                </IonCol>
              </IonRow>

              <IonRow>
                <IonCol>
                  <div className="total_type1 type2">
                    <div className="option_box1">
                      <strong>총 결제금액</strong>
                      <p className="modam_price">
                        <em>{numberWithCommas(totalPrice)}</em>원
                      </p>
                    </div>
                  </div>
                </IonCol>
              </IonRow>

              <IonRow className="submit_wrap">
                <IonCol>
                  <IonButton className="submit" mode="ios" expand="full" onClick={() => orderSubmit()}>
                    <em>{numberWithCommas(totalPrice)}</em>원 결제하기
                  </IonButton>
                </IonCol>
              </IonRow>
            </>
          )}

          {!chkCartData && (
            <IonRow className="cart_not_exist">
              <IonCol className="ion-text-center">
                <IonIcon icon={bagHandleOutline} size={'large'}></IonIcon>
                <IonText>
                  <p>장바구니에 담긴 상품이 없습니다.</p>
                  <p>원하는 상품을 장바구니에 담아보세요!</p>
                </IonText>
                <IonButton expand="full" routerLink={url}>
                  <div className="arrow">모던한담 쇼핑 계속하기</div>
                </IonButton>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
        <DefaultFooter />
      </IonContent>
    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state) => ({
    category: selectors.getCategory(state),
    user: selectors.getUser(state),
    codes: selectors.getCodes(state),
  }),
  mapDispatchToProps: { setCartTotal, setPayCertify, setPayProduct },
  component: React.memo(MyCartPage),
});
