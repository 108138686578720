/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import './CartModal.scss';
import { IonButton, IonImg } from '@ionic/react';
import routes from '../../routes';
import { Product } from '../../models/Product';

interface CartModalProps {
  item: boolean;
}
const CartModal: React.FC<CartModalProps> = ({ item, children }) => {
  const [clicked, setClicked] = useState('');
  const [product, setProduct] = useState<Product>();

  function pop_close() {
    setClicked('');
  }

  useEffect(() => {
    if (item) {
      setClicked('on');
    }

    if (children) {
      setProduct(Object(children));
    }
  }, [children, item]);

  return (
    <>
      <div className={`wrap ${clicked && 'on'}`}></div>
      <div className={`cart_modal ${clicked && 'on'}`}>
        <p>
          <span>선택하신</span>
          <span className="product_item">
            {product?.productName}
            {''}
            <em> 1</em>개
          </span>
          를<span>장바구니에 담았어요.</span>
        </p>
        <div className="img_box">
          <IonImg src={product?.imageUrl} />
        </div>
        <div className="btn_box">
          <IonButton type="button" expand="full" routerLink={routes.CART} onClick={() => setClicked('')}>
            장바구니에 가보시겠어요?
          </IonButton>
        </div>
        <IonButton className="close" onClick={() => pop_close()}>
          닫기
        </IonButton>
      </div>
    </>
  );
};

export default CartModal;
