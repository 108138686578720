export interface OrderPayProductList {
  orderId: number; //주문 아이디
  productId: number; // 상품 아이디
  productName: string; // 상품 명
  productNormalPrice: number; // 상품 정상가
  productQuantity: number; // 상품 수량
  productSalePrice: number; // 상품 판매가
  productDiscountPrice: number; // 상품 할인
  productTaxFreeAmount: number; // 상품 면세 금액(단품)
  productStockQuantity: number; // 현재 상품 재고
  productStatusType: string; // 현재 상품 판매 상태
  imageUrl: string; // 상품(이미지) URL
  productAmount: number; // 상품 총액
  viewOrder: number; // 보기 순서
  deliveryRequestMemo: string; //배송시 요청사항
}

export interface Order {
  orderId: number; // 아이디
  orderNo: string; // 주문 번호
  deleted: boolean; // 삭제여부
  enabled: boolean; // 사용 여부
  orderAmount: number; // 상품 총액
  orderGrandTotal: number; // 배송비까지 포함한 전체 결제금액
  orderDeliveryAmount: 0; // 주문 배송비 총액
  orderDiscountAmount: 0; // 주문 할인 총액
  orderNormalAmount: number; //주문 정상가 총액
  orderTaxFreeAmount: number; //주문 상품 면세 총액
  orderTotalQuantity: number; // 주문 총 수량
  orderStatusType: string; // 주문 상태 (주문 불가능/가능 여부)
  purchaserUserId: number; // 사용자(구매자) 아이디
  createdAt: string; // 생성일
  createdBy: number; // 생성자
  orderDeliveryFeeInfos: Array<OrderDeliveryFeeInfo>; // 배송비 계산근거
}

export interface OrderDeliveryFeeInfo {
  orderId: number; // 주문아이디
  orderDeliveryFeeInfoSeq: number;
  deliveryFee: number; // 배송비
  reason: string; // 계산근거
}

export interface UserDestinations {
  destId: number; // 배송지 아이디
  userId: number; // 사용자 아이디
  destSeq: number; // 보기 순서
  destAddress: string; // 주소
  destAddressDetail: string; // 상세
  destDefault: boolean; // 기본 배송지
  destEnterType: string; // 배송지 출입 방법
  destName: string; //  배송지 명
  destRecipientName: string; // 배송지 수령인
  destRecipientEmail: string; // 배송지 수령인 이메일 주소
  destRecipientPhoneNo: string; // 배송지 수령인 연락처
  destRecipientCellphoneNo: string; // 배송지 수령인 휴대전화
  destZipcode: string; // 배송지 우편 번호
  enabled: boolean; // 사용여부
}

export interface OrderPayment {
  orderId: number; // 주문번호
  orderPaymentType: string; // 결제수단
  orderPaymentSeq: number;
  orderNo: string; // 주문번호 (iamport merchant_uid)
  paymentStatusType: string; // 결제상태
  paymentAmount: number; // 결제를 수행해야 할 금액
  enabled: boolean;
  createdAt?: string; // 재요청시 생략될 수 있음
  createdBy?: number; // 재요청시 생략될 수 있음
  updatedAt: string;
  updatedBy: number;
  deleted: boolean;
}

export interface PGSetting {
  // TODO: improvement
  settingId: number;
  iamportUID: string;
  pgName: string;
  pgMID: string;
  pgMode: 'PROD' | 'DEV'; // enum string
  pgSeq: number;
  tier: string;
  memo: string;
}

export interface IamportResultRedirectParams {
  imp_uid: string | null;
  merchant_uid: string;
  // imp_success: string; // deprecated
  error_msg?: string;

  // for logging
  orderId: number;
}

export interface PaymentVerificationResult {
  payMethod: string; // 결제방식
  status: string;
  merchantUid: string; // 주문번호
  vbankNum?: string; // 가상계좌 번호
  vbankName?: string; // 가상계좌 은행명
  vbankHolder?: string; // 가상계좌 예금주명
  vbankDate?: Date; // 가상계좌 입금기한
}

export interface IamportVbankDueDate {
  iamportDueDateTime: string;
  dueDate: string;
}

export enum IamportPayMethod {
  CREDIT_CARD = 'card',
  VIRTUAL_ACCOUNT = 'vbank',
  // TODO
}
